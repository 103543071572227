<template>
  <div>
    <!-- Form nhập dữ liệu -->
    <div class="input-form">
      <div v-for="(item, idx) in displayedCoins" :key="item.id" class="input-group">
        <v-select v-model="coinNames[idx]" :options="coins" label="coinName" class="select-point"
          placeholder="Search for a coin..." />
      </div>
    </div>

    <div class="form-group-horizontal">
      <div class="select-form">
        <select v-model="periods">
          <option value="5m">5m</option>
          <option value="1h">1h</option>
          <option value="4h">4h</option>
        </select>
      </div>
      <div class="search-button">
        <button @click="handleSearch">Tìm kiếm</button>
      </div>
    </div>

    <!-- Biểu đồ -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>Biểu đồ {{ (typeof arrayCoin[index - 1] === 'object') ? arrayCoin[index - 1].coinName : arrayCoin[index - 1]
          }}</h3>

      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondChartIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container third-chart">
          <canvas :id="thirdChartIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourChartIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { API_URL } from '../config';
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

ChartJS.register(
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  components: {
    VSelect
  },
  data() {
    return {
      selectedCoin: null,
      chartIds: Array.from({ length: 10 }, (_, i) => `chart-${i}`),
      secondChartIds: Array.from({ length: 10 }, (_, i) => `second-chart-${i}`),
      thirdChartIds: Array.from({ length: 10 }, (_, i) => `third-chart-${i}`),
      fourChartIds: Array.from({ length: 10 }, (_, i) => `four-chart-${i}`),
      coinNames: Array(10).fill(''),
      periods: '5m',
      charts: [],
      secondCharts: [],
      thirdCharts: [],
      fourCharts: [],
      chartDataSets: [],
      secondChartDataSets: [],
      thirdChartDataSets: [],
      fourChartDataSets: [],
      loading: false,
      coins: [],
      totalChart: 0,
      arrayCoin: [],
      displayedCoins: []
    };
  },
  async created() {
    await this.fetchCoins();
  },
  methods: {
    async fetchCoins() {
      try {
        const response = await fetch(`${API_URL}/contract/get-favorite`);
        const data = await response.json();
        this.coins = data.map((coin) => ({
          id: coin.id,
          coinName: `${coin.coinName}USDT`,
          favoriteFlag: coin.favoriteFlag,
        }));
        const allCoins = this.coins
          .map(coin => ({ ...coin, isSelected: coin.favoriteFlag === "1" }))
          .sort((a, b) => b.isSelected - a.isSelected);
        this.coinNames = allCoins.slice(0, 10).map(coin => (coin.isSelected ? coin.coinName : ""));
        this.coinNames = [...this.coinNames, ...Array(10 - this.coinNames.length).fill("")];
        this.displayedCoins = allCoins.slice(0, 10);
      } catch (error) {
        console.error("Error fetching coins:", error);
      }
    },
    async handleSearch() {
      console.log(this.coinNames);
      const filteredArray = this.coinNames
        .filter(item => item != null && item !== "")
        .map(item => typeof item === 'string' ? item : item.coinName);
      this.coinNames = filteredArray
      for (let i = 0; i < filteredArray.length; i++) {
        this.loading = true;
        const dataCoin = await this.fetchNotionalData(this.coinNames[i], this.periods);
        this.chartDataSets[i] = dataCoin;
        await this.fetchSecondApiDataForCoin(this.coinNames[i], i, this.periods);
        await this.fetchThirdApiDataForCoin(this.coinNames[i], i, this.periods);
        await this.fetchFourApiDataForCoin(this.coinNames[i], i, this.periods);
      }
      this.totalChart = filteredArray.length;
      this.loading = false;
      this.arrayCoin = this.coinNames;
      this.createCharts();
    },

    async fetchNotionalData(coinName, period) {
      try {
        const requestBody = {
          coinName: coinName,
          period: period,
        };
        const response = await fetch(`${API_URL}/contract/interest`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const convertData = data.map(item => {
          const [dayMonth, time] = item.fundingTime.split(' ');
          const [day, month, year] = dayMonth.split('-');
          const [hour, minute] = time.split(':');

          const notionalValue = parseFloat(item.notionalValue.replace(/,/g, ''));
          const date = new Date(year, month - 1, day, hour, minute);

          return { x: date, y: notionalValue };
        });

        return convertData.sort((a, b) => a.x - b.x);

      } catch (error) {
        console.error(`Lỗi khi tải dữ liệu cho ${coinName}:`, error.message || error);
        return [];
      }
    },

    async fetchSecondApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          period: period,
          limit: 30
        };

        const response = await fetch(`${API_URL}/contract/toplongshort-account`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data.map(item => {
          if (item.timeGet) {
            return {
              x: item.timeGet,
              y: parseFloat(item.longShortRatio),
              longPercent: parseFloat(item.longPercent),
              shortPercent: parseFloat(item.shortPercent),
              longShortRatio: parseFloat(item.longShortRatio),
            };
          }
          return null;
        }).filter(item => item !== null);
        console.log(result);
        this.secondChartDataSets[index] = result;
      } catch (error) {
        console.error(`Lỗi khi tải dữ liệu từ API mới cho ${coinName}:`, error.message || error);
      }
    },

    async fetchThirdApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          period: period,
          limit: 30
        };

        const response = await fetch(`${API_URL}/contract/toplongshort-positions`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data.map(item => {

          if (item.timeGet) {
            return {
              x: item.timeGet,
              y: parseFloat(item.longShortRatio),
              longPercent: parseFloat(item.longPercent),
              shortPercent: parseFloat(item.shortPercent),
              longShortRatio: parseFloat(item.longShortRatio),
            };
          }
          return null;
        }).filter(item => item !== null);

        this.thirdChartDataSets[index] = result;
      } catch (error) {
        console.error(`Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`, error.message || error);
      }
    },
    async fetchFourApiDataForCoin(coinName, index, period) {
      try {
        const requestBody = {
          symbol: coinName,
          period: period,
          limit: 30
        };

        const response = await fetch(`${API_URL}/contract/longshort`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data.map(item => {

          if (item.timeGet) {

            return {
              x: item.timeGet,
              y: parseFloat(item.longShortRatio),
              longPercent: parseFloat(item.longPercent),
              shortPercent: parseFloat(item.shortPercent),
              longShortRatio: parseFloat(item.longShortRatio),
            };
          }
          return null;
        }).filter(item => item !== null);

        this.fourChartDataSets[index] = result;
      } catch (error) {
        console.error(`Lỗi khi tải dữ liệu từ API thứ ba cho ${coinName}:`, error.message || error);
      }
    },
    createCharts() {
      this.$nextTick(() => {
        this.chartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (this.charts[index]) {
              this.charts[index].destroy();
            }

            const dataSet = this.chartDataSets[index] || [];

            this.charts[index] = new ChartJS(ctx, {
              type: 'line',
              data: {
                datasets: [
                  {
                    label: `Giá trị bề mặt của các khoản lệnh đang mở`,
                    data: dataSet,
                    borderColor: '#3498db',
                    backgroundColor: 'rgba(52, 152, 219, 0.5)',
                    fill: true,
                    tension: 0,
                    borderWidth: 2,
                  },
                ],
              },
              options: {
                plugins: {
                  legend: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: `Open Interest(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                scales: {
                  x: {
                    type: 'time',
                    time: {
                      unit: this.periods === '1h' || this.periods === '4h' ? 'hour' : 'minute',
                    },
                    labels: dataSet.map(item => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      stepSize: this.periods === '1h' ? '1' :
                        this.periods === '4h' ? '4' :
                          '5',
                      callback: function (value) {
                        const date = new Date(value);
                        const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
                        return time;
                      },
                    },
                    stacked: true,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              },
            });
          }
        });

        this.secondChartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (this.secondCharts[index]) {
              this.secondCharts[index].destroy();
            }

            const dataSet = this.secondChartDataSets[index] || [];
            const minValue = Math.min(...dataSet.map(item => item.longShortRatio));
            const roundedValue = Math.floor(minValue / 0.05) * 0.05;
            const startValue = minValue - roundedValue <= 0.05 ? roundedValue : roundedValue + 0.05;

            this.secondCharts[index] = new ChartJS(ctx, {
              type: 'bar',
              data: {
                datasets: [
                  {
                    label: "Tỷ lệ Long/Short (Tài khoản)",
                    type: "line",
                    data: dataSet,
                    borderColor: "rgba(0, 0, 0, 1)",
                    pointBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointBorderColor: "rgba(0, 0, 0, 1)",
                    pointBorderWidth: 1.5,
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    fill: true,
                    tension: 0.4,
                    borderWidth: 2,
                    yAxisID: 'y-line',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                  {
                    label: "% Tài khoản Long",
                    data: dataSet.map(item => item.longPercent),
                    backgroundColor: "rgba(0, 209, 112, 0.8)",
                    stack: "Stack 0",
                    yAxisID: 'y-bar',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                  {
                    label: "% Tài khoản Short",
                    data: dataSet.map(item => item.shortPercent),
                    backgroundColor: "rgba(231, 76, 60, 0.8)",
                    stack: "Stack 0",
                    yAxisID: 'y-bar',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: `Top Long Short Account(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        const dataset = tooltipItem.dataset;
                        const rawData = dataset.meta.customValue;
                        const index = tooltipItem.dataIndex;
                        const shortPercent = rawData[index].shortPercent || 0;
                        const longPercent = rawData[index].longPercent || 0;
                        const longShortRatio = rawData[index].longShortRatio || 0;
                        return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                      }
                    }
                  }
                },
                scales: {
                  x: {
                    type: 'time',
                    time: {
                      unit: this.periods === '1h' || this.periods === '4h' ? 'hour' : 'minute',
                    },
                    labels: dataSet.map(item => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      stepSize: this.periods === '1h' ? '1' :
                        this.periods === '4h' ? '4' :
                          '5',
                      callback: function (value) {
                        const date = new Date(value);
                        const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
                        return time;
                      },
                    },
                    stacked: true,
                  },
                  'y-line': {
                    id: "y-line",
                    position: 'right',
                    grid: {
                      drawOnChartArea: false,
                    },
                    beginAtZero: false,
                    min: startValue,
                    ticks: {
                      callback: function (value) {
                        return value.toFixed(2);
                      },
                    },
                  },
                  y: {
                    display: false,
                    grid: {
                      display: false,
                    },
                  },

                },
              },
            });
          }
        });

        this.thirdChartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (this.thirdCharts[index]) {
              this.thirdCharts[index].destroy();
            }

            const dataSet = this.thirdChartDataSets[index] || [];
            const minValue = Math.min(...dataSet.map(item => item.longShortRatio));
            const roundedValue = Math.floor(minValue / 0.05) * 0.05;
            const startValue = minValue - roundedValue <= 0.05 ? roundedValue : roundedValue + 0.05;

            this.thirdCharts[index] = new ChartJS(ctx, {
              type: 'bar',
              data: {
                datasets: [
                  {
                    label: "Tỷ lệ Long/Short (Vị thế)",
                    type: "line",
                    data: dataSet,
                    borderColor: "rgba(0, 0, 0, 1)",
                    pointBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointBorderColor: "rgba(0, 0, 0, 1)",
                    pointBorderWidth: 1.5,
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    fill: true,
                    tension: 0.4,
                    borderWidth: 2,
                    yAxisID: 'y-line',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                  {
                    label: "% Vị thế Long",
                    data: dataSet.map(item => item.longPercent),
                    backgroundColor: "rgba(0, 209, 112, 0.8)",
                    stack: "Stack 0",
                    yAxisID: 'y-bar',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                  {
                    label: "% Vị thế Short",
                    data: dataSet.map(item => item.shortPercent),
                    backgroundColor: "rgba(231, 76, 60, 0.8)",
                    stack: "Stack 0",
                    yAxisID: 'y-bar',
                    meta: {
                      customValue: dataSet,
                    },
                  },

                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: `Top Long Short Positions(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        const dataset = tooltipItem.dataset;
                        const rawData = dataset.meta.customValue;
                        const index = tooltipItem.dataIndex;
                        const shortPercent = rawData[index].shortPercent || 0;
                        const longPercent = rawData[index].longPercent || 0;
                        const longShortRatio = rawData[index].longShortRatio || 0;
                        return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                      }
                    }
                  }
                },
                scales: {
                  x: {
                    type: 'time',
                    time: {
                      unit: this.periods === '1h' || this.periods === '4h' ? 'hour' : 'minute',
                    },
                    labels: dataSet.map(item => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      stepSize: this.periods === '1h' ? '1' :
                        this.periods === '4h' ? '4' :
                          '5',
                      callback: function (value) {
                        const date = new Date(value);
                        const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
                        return time;
                      },
                    },
                    stacked: true,
                  },

                  'y-line': {
                    id: "y-line",
                    position: 'right',
                    grid: {
                      drawOnChartArea: false,
                    },
                    beginAtZero: false,
                    min: startValue,
                    ticks: {
                      callback: function (value) {
                        return value.toFixed(2);
                      },
                    },
                  },
                  y: {
                    display: false,
                    grid: {
                      display: false,
                    },
                  },

                },
              },
            });
          }
        });
        this.fourChartIds.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (this.fourCharts[index]) {
              this.fourCharts[index].destroy();
            }

            const dataSet = this.fourChartDataSets[index] || [];
            const minValue = Math.min(...dataSet.map(item => item.longShortRatio));
            const roundedValue = Math.floor(minValue / 0.05) * 0.05;
            const startValue = minValue - roundedValue <= 0.05 ? roundedValue : roundedValue + 0.05;
            this.fourCharts[index] = new ChartJS(ctx, {
              type: 'bar',
              data: {
                datasets: [
                  {
                    label: "Tỷ lệ Long/Short",
                    type: "line",
                    data: dataSet,
                    borderColor: "rgba(0, 0, 0, 1)",
                    pointBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointBorderColor: "rgba(0, 0, 0, 1)",
                    pointBorderWidth: 1.5,
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    fill: true,
                    tension: 0.4,
                    borderWidth: 2,
                    yAxisID: 'y-line',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                  {
                    label: "% Tài khoản Long",
                    data: dataSet.map(item => item.longPercent),
                    backgroundColor: "rgba(0, 209, 112, 0.8)",
                    stack: "Stack 0",
                    yAxisID: 'y-bar',
                    meta: {
                      customValue: dataSet,
                    },
                  },
                  {
                    label: "% Tài khoản Short",
                    data: dataSet.map(item => item.shortPercent),
                    backgroundColor: "rgba(231, 76, 60, 0.8)",
                    stack: "Stack 0",
                    yAxisID: 'y-bar',
                    meta: {
                      customValue: dataSet,
                    },
                  },

                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: `Long/Short ratio(${this.coinNames[index]})`,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        const dataset = tooltipItem.dataset;
                        const rawData = dataset.meta.customValue;
                        const index = tooltipItem.dataIndex;
                        const shortPercent = rawData[index].shortPercent || 0;
                        const longPercent = rawData[index].longPercent || 0;
                        const longShortRatio = rawData[index].longShortRatio || 0;
                        return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                      }
                    }
                  }
                },
                scales: {
                  x: {
                    type: 'time',
                    time: {
                      unit: this.periods === '1h' || this.periods === '4h' ? 'hour' : 'minute',
                    },
                    labels: dataSet.map(item => item.x),
                    ticks: {
                      maxTicksLimit: 30,
                      stepSize: this.periods === '1h' ? '1' :
                        this.periods === '4h' ? '4' :
                          '5',

                      callback: function (value) {
                        const date = new Date(value);
                        const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
                        return time;
                      },
                    },
                    stacked: true,
                  },

                  'y-line':
                  {
                    position: 'right',
                    beginAtZero: false,
                    min: startValue,
                    ticks: {
                      callback: function (value) {
                        return value.toFixed(2);
                      },
                    },
                  },

                  y: {
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      beginAtZero: false,
                      callback: function (value) {
                        return value.toFixed(2);
                      },
                    },
                  },

                },
              },
            });
          }
        });
      });
    },
  },
};
</script>


<style scoped>
.form-group-horizontal {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-form select,
.search-button button {
  padding: 8px 12px;
  font-size: 14px;
}

.input-form {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom: 20px;

}

.input-form:hover,
.select-point:hover {
  cursor: pointer;
}

.input-group {
  margin-bottom: 10px;
}

.input-group input {
  width: 80%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.select-form {
  margin-bottom: 10px;
  width: 120px;
}

select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
}

button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

.chart-grid {
  margin: 0 auto;
  width: 100%;
}

.chart-grid .chart-box {
  border: 2px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.chart-container {
  flex: 1 1 calc(50% - 40px);
  max-width: calc(50% - 40px);
  min-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-chart,
.second-chart,
.third-chart,
.four-chart {
  width: 100%;
}

.chart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
}
</style>