<template>
  <div>
    <button @click="fetchData">Search</button> <!-- Nút Search để gọi API -->
    <!-- Kiểm tra dữ liệu trước khi render biểu đồ -->
    <line-chart v-if="chartData.labels.length > 0" :chart-data="chartData" :options="chartOptions" />
    <p v-else>Loading...</p> <!-- Hiển thị thông báo nếu chưa có dữ liệu -->
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  components: {
    LineChart: Line,
  },
  data() {
    return {
      chartData: {
        labels: [],  // Đảm bảo labels luôn là mảng
        datasets: [
          {
            label: 'Long Percent',
            data: [],
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
            tension: 0.1,
          },
          {
            label: 'Long Short Ratio',
            data: [],
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            fill: false,
            tension: 0.1,
          }
        ]
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
            }
          },
          y: {
            beginAtZero: true
          }
        }
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch('http://localhost:8080/contract/toplongshort-positions', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            symbol: "1000PEPEUSDT",
            period: "1h",
            limit: 30
          })
        });

        const data = await response.json();
        if (data && Array.isArray(data)) {
          this.processData(data);
        } else {
          console.error('Dữ liệu không hợp lệ');
        }
      } catch (error) {
        console.error('Lỗi khi lấy dữ liệu:', error);
      }
    },
    processData(data) {
      const labels = [];
      const longPercent = [];
      const longShortRatio = [];

      data.forEach(item => {
        labels.push(new Date(item.timeGet).toLocaleTimeString());
        longPercent.push(item.longPercent);
        longShortRatio.push(item.longShortRatio);
      });

      // Cập nhật dữ liệu chartData sau khi xử lý
      this.chartData.labels = labels;
      this.chartData.datasets[0].data = longPercent;
      this.chartData.datasets[1].data = longShortRatio;

      // Đảm bảo render lại sau khi dữ liệu đã thay đổi
      this.$nextTick(() => {
        console.log('Dữ liệu đã được cập nhật và sẵn sàng render lại biểu đồ');
      });
    }
  }
};
</script>

<style scoped>
/* Tùy chỉnh CSS cho biểu đồ */
</style>
