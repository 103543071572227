import { createRouter, createWebHistory } from 'vue-router';
import LineChart from './components/LineChart.vue';
import DemoComponent from './components/DemoComponent.vue';
import FavoriteCoin from './components/FavoriteCoin.vue';

const routes = [
  {
    path: '/line-chart',
    name: 'LineChart',
    component: LineChart
  },
  {
    path: '/favorite',
    name: 'favoriteCoin',
    component: FavoriteCoin
  },
  {
    path: '/demo',
    name: 'demoValue',
    component: DemoComponent
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;